import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  private baseUrl = 'https://api.citec.com.ec/citec-master/'

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization':'abcdefgh12345678',
    'Origin':'localhost'
 
})

  constructor(private httpClient: HttpClient) { }

 get(url, parametros) {  
    var params  = new HttpParams()
    for (const key in parametros) {
    params = params.append(key, parametros[key])
    }
    return this.httpClient.get(this.baseUrl+url,{params: params, headers: this.headers})
  }
  post(url, body) {
  
      return this.httpClient.post(this.baseUrl + url, body);
 
  }
}
