<!-- speaker section start-->
<section class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="top-header m-b-50"> 
            <div class="row">
                <div class="col-md-12">
                   
                    <p class="title-all-ser">Todos los servicios</p>
                    <br><br>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 m-b-10" *ngFor="let data of category">
                    <a class="service-all-title" routerLink="/categoria/{{data.id}}"  [queryParams]="{name:data.nombre}">{{data.nombre}} </a>
                    
                    <hr class="black">
                    <ul *ngFor="let subdata of data.servicios">
                        <li>

                            <a routerLink="/subcategoria/{{subdata.id}} "  [queryParams]="{name:subdata.nombre}" class="link-ser service-all-text">
                                {{subdata.nombre}}</a>
                        </li>

                    </ul>

                </div>

            </div>



            </div>
        </div>
    </div>
</section>
<!-- speaker section end-->