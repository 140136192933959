<!--footer start-->
<footer class="agency footer2 p-b-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-12 set-first">
                <div class="logo-sec">
                    
                    <div class="footer-contant">
                        <img src="assets/images/logo/logo-citec.png" alt="" class="img-fluid footer-logo">
                     
                  
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">BENEFICIOS</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">BENEFICIOS</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">REPRESENTATIVIDAD</a>
                            </li>
                            <li class="">
                                <a href="#">DIFUCIÓN DE NUESTROS SOCIOS</a>
                            </li>
                            <li class="">
                                <a href="#">ALIANZAS</a>
                            </li>
                            <li class="">
                                <a href="#">FINANCIAMIENTO</a>
                            </li>
                            <li class="">
                                <a href="#">EXPORTACIÓN DE SERVICIOS</a>
                            </li>
                            <li class="">
                                <a href="#">DESCUENTOS PARA SOCIOS</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">COMUNIDAD</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">COMUNIDAD</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">SLACK</a>
                            </li>
                            <li class="">
                                <a href="#">IMPACTO A LA SOCIEDAD</a>
                            </li>
                           
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">CAPACITACIÓN</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">CAPACITACIÓN</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">CITEC TALKS</a>
                            </li>
                            <li class="">
                                <a href="#">WEBINARS DE NUESTROS SOCIOS</a>
                            </li>
                            <li class="">
                                <a href="#">BOOTCAMPS</a>
                            </li>
                         
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">RECURSOS</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">RECURSOS</h5>
                    <div class="footer-lists op-text">
                        <ul>
                            <li class="">
                                <a href="#">BLOGS</a>
                            </li>
                            <li class="">
                                <a href="#">PLAN DE TRABAJO 2022</a>
                            </li>
                            <li class="buttons">
                                <a href="#">NDA ESTÁNDAR PARA SOCIOS CITEC</a>
                            </li>
                            <li class="buttons">
                                <a href="#">GENERACIÓN DE DATA</a>
                            </li>
                            <li class="buttons">
                                <a href="#">BIBLIOTECA DE EVENTOS</a>
                            </li>
                          
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!--footer end-->
      
    </div>
</footer>
<!--footer end-->
<app-tap-to-top></app-tap-to-top>

