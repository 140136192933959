import { Component, OnInit } from '@angular/core';
import { ConnectionService } from 'src/app/shared/service/connection.service';

@Component({
  selector: 'app-all-services',
  templateUrl: './all-services.component.html',
  styleUrls: ['./all-services.component.scss']
})
export class AllServicesComponent implements OnInit {
  category: any;

  constructor(private apiService: ConnectionService) { }

  ngOnInit(): void {
    this.get_services()
  }
  get_services() {
    this.apiService.get("api_catalogo/public/api/categorias/", {}).
      subscribe((response: any) => {
        this.category = response.data
        console.log("lista de categorias", response)


      })
  }

}
