<!-- price-plan Start-->
<br><br>
<h3 class="txt-related">OTRAS EMPRESAS OFRECEN ESTE SERVICIO</h3>
<section class="agency pricing price-bg p-t-2" id="plan">

  <div class="container">
    <div class="row">
      <div class="col-12">

        <owl-carousel-o [options]="pricingcarouselOptions" class="pricing-slider price-margin">
          <ng-container *ngFor="let data of parnert">
            <ng-template carouselSlide class="item">
              <a routerLink="/detail/{{data.id}}" target="_blank">
                <div class="blog-agency h-100">
                  <div class="blog-contain h-100">   

                    <div class="center-content divLogo">
                      <img [src]="data.logo" alt="Sin logo" class="logo-parnert">
                    </div>
                    <div class="img-container">
                      <div class="">
                        <div class="blog-info">
                       
                          <h5 class="blog-head">{{data.nombre}}</h5>
                          <div class="btn-group" *ngFor="let service of data.servicios "
                            routerLink="/subcategoria/{{service.id}}" [queryParams]="{name:service.nombre}">
                            <span class="tag-service4"> {{service.nombre}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- price-plan end-->