<!-- Nav Start-->
<header class="agency nav-abs custom-scroll">
    <div class="header-logo ">
        <div class="row">
            <div class="col">
                <nav class="fondo-superior">

                    <a href="#" class="logo-light d-inline-block m-r-auto padding-l"><img
                            src="assets/images/logo/logo-citec.png" alt="" class="img-fluid discard"><span
                            class="catalogo discard">Catálogo de servicios</span> </a>

                    <a class="btnsobre active" href="https://landing.citec.com.ec" target="_blank">SOBRE CITEC</a>

                    <a href="https://landing.citec.com.ec/#/afiliacion" target="_blank"
                        class="btnafiliate active">AFÍLIATE</a>


                </nav>
                <nav>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Nav end-->