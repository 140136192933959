import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CountToModule } from 'angular-count-to';
// import { AngularTiltModule } from 'angular-tilt';
// import { ScrollToModule } from 'ng2-scroll-to-el';
import { Ng5SliderModule } from 'ng5-slider';
import { CarouselModule } from 'ngx-owl-carousel-o';
// import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SharedModule } from '../shared/shared.module';
import { AgencyContentComponent } from './agency/agency-content/agency-content.component';
import { AgencyCopyrightComponent } from './agency/agency-copyright/agency-copyright.component';
import { AgencyCounterComponent } from './agency/agency-counter/agency-counter.component';
import { AgencyFooterComponent } from './agency/agency-footer/agency-footer.component';
import { AgencyHeaderComponent } from './agency/agency-header/agency-header.component';
import { AgencyNavComponent } from './agency/agency-nav/agency-nav.component';
import { AgencyPricingComponent } from './agency/agency-pricing/agency-pricing.component';
import { AgencyServicesComponent } from './agency/agency-services/agency-services.component';

// Agency Layout
import { AgencyComponent } from './agency/agency.component';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { AgencySocioComponent } from './agency/agency-socio/agency-socio.component';
import { AgencyCategoryComponent } from './agency/agency-category/agency-category.component';
import { AgencyListCategoryComponent } from './agency/agency-list-category/agency-list-category.component';
import { AgencySidebarComponent } from './agency/agency-sidebar/agency-sidebar.component';
import { CategoryComponent } from './agency/category/category.component';
import { RelatedServicesComponent } from './agency/related-services/related-services.component';
import { ParnertListComponent } from './agency/parnert-list/parnert-list.component';
import { SearchServicesComponent } from './agency/search-services/search-services.component';
import { AllServicesComponent } from './agency/all-services/all-services.component';
import { SortPipe } from '../shared/pipe/sort.pipe';
import { AgencySubcategoryComponent } from './agency/agency-subcategory/agency-subcategory.component';
import { InputSearchComponent } from './agency/input-search/input-search.component';



// const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {};

@NgModule({
  declarations: [
    AgencyComponent, AgencyNavComponent, AgencyHeaderComponent, AgencyContentComponent, AgencyServicesComponent, AgencyCounterComponent, AgencyPricingComponent, AgencyFooterComponent, AgencyCopyrightComponent, AgencySocioComponent, AgencyCategoryComponent, AgencyListCategoryComponent, AgencySidebarComponent, CategoryComponent, RelatedServicesComponent, ParnertListComponent, SearchServicesComponent, AllServicesComponent, AgencySubcategoryComponent, InputSearchComponent],

  imports: [
    CommonModule,
    LayoutsRoutingModule,
    // SwiperModule,
    CarouselModule,
    NgbModule,
    SharedModule,
    CountToModule,
    // AngularTiltModule,
    // ScrollToModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    
  
  ],

  exports: [],

  // providers: [
  //   {
  //     provide: SWIPER_CONFIG,
  //     useValue: DEFAULT_SWIPER_CONFIG
  //   }
  // ]
})

export class LayoutsModule { }
