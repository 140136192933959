<div class="collection-collapse-block top_filter border-0 open">

  <div class="collection-collapse-block-content">
    <div class="collection-brand-filter">
      <ul class="mt-3" *ngFor="let subcategory of serviceP">
        <li class="list-service" routerLink="/subcategoria/{{subcategory.id}}"
          [queryParams]="{name:subcategory.nombre}"> {{subcategory.nombre}}</li>

      </ul>
      <a class="btn btn-secondary btn-all mt-4 mb-4" [routerLink]="['/servicios']">VER TODOS LOS SERVICIOS</a>
    </div>

  </div>
</div>

