import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ConnectionService } from 'src/app/shared/service/connection.service';

@Component({
  selector: 'app-agency-socio',
  templateUrl: './agency-socio.component.html',
  styleUrls: ['./agency-socio.component.css']
})
export class AgencySocioComponent implements OnInit {
  detail: any = [];
  id: any;
  logo: any;
  pageWeb: any;
  pageHide = false;
  address: any;
  phone: any;
  services: any;
  contact: any;
  buscando:string;


  constructor(
    private apiService: ConnectionService,
    private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.formContactEmpresa.get('id_empresa').setValue(this.id)
    this.get_detail_socio()
  }
  get_detail_socio() {  
    this.apiService.get("api_empresas/public/api/empresa/" + this.id, {}).
      subscribe((response: any) => {
        this.detail = Object.values(response)
        this.logo = response.data.serv_logo
        this.address = response.data.direccion
        this.phone = response.data.telefono
        this.services = response.data.servicios
        this.contact = response.data.contactos        
        response.data.redes_sociales.forEach(item => {
          this.pageWeb = item.link
        });



      })
  }
  formContactEmpresa = new FormGroup({
    'nombre': new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('^\s*([a-zA-ZáéíóúAÉÍÓÚÑñ0-9 .,!?¡=¿-]+)\s*$')]),
    'email': new FormControl('', [Validators.required, Validators.email]),
    'telefono': new FormControl('', [Validators.required, Validators.maxLength(30)]),
    'mensaje': new FormControl('', [Validators.required, Validators.pattern('^\s*([a-zA-ZáéíóúAÉÍÓÚÑñ0-9 .,!?¡=¿-]+)\s*$')]),
    'id_empresa': new FormControl('',[]),
  });
  sendContact = false
  inputRequired = false
  mesage: any
  onSummit() {
    if (this.formContactEmpresa.get('nombre').value == '') {
      this.inputRequired = true
      this.mesage = "Nombre es requerido"
    }
    if (this.formContactEmpresa.get('email').value == '') {
      this.inputRequired = true
      this.mesage = "Email es requerido"
    }
    else if (this.formContactEmpresa.get('telefono').value == '') {
      this.inputRequired = true
      this.mesage = "Teléfono es requerido"
    }
    else if (this.formContactEmpresa.get('mensaje').value == '') {
      this.inputRequired = true
      this.mesage = "Mensaje es requerido"
    } else {
      this.apiService.post('api_empresas/public/api/mail/send', this.formContactEmpresa.value).subscribe(res => {
        console.log("formulario", res)
        this.sendContact = true
        this.formContactEmpresa.reset()
        this.inputRequired = false
        this.mesage = "Datos enviados"
      },
        err => {
          alert('Existió un error al guardar')
          console.log('este es el error', err);
        })

    }

  }
  searchRedirect(){
    this.router.navigate(['/search',this.buscando ]);
  }
}
