import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectionService } from 'src/app/shared/service/connection.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  id: any
  parnert: any
  constructor(
    private apiService: ConnectionService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    
    this.get_parnert_similary()
  }

  get_parnert_similary() {
    this.apiService.get("api_empresas/public/api/empresas/search/serviciosSimilares/" + this.id, { row: 20, page: 1 }).
      subscribe((response: any) => {
        this.parnert = response.data
       


      })
  }



  pricingcarouselOptions = {
    items: 3,
    margin: 20,
    nav: true,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    navText: ['<img src="./assets/images/agency/testimonial/left.png">', '<img src="./assets/images/agency/testimonial/right.png">'],

    loop: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
        margin: 10
      },
      576: {
        items: 2,
        margin: 10
      },
      991: {
        items: 2,
        margin: 15
      },
      992: {
        items: 3,
        margin: 15
      },
      1000: {
        items: 3
      }
    }
  }
}
