<!-- testimonial section start-->
<section class="agency testimonial format testimonial-bg" >
    <div class="container">
       
        <div class="row ">
            <div class="col-md-5 p-t-50">
                <div class="format-container m-b-30 ">
                    <p class="titulo-buscados">SERVICIOS MÁS BUSCADOS</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                
                <owl-carousel-o [options]="testimonialCarouselOptions" class="testimonial-slider">
                    <ng-container *ngFor="let service of category">
                        <ng-template carouselSlide class="item">
                            <div class="testimonial-container testimonial-container2 m-0" routerLink="/categoria/{{service.id}}" [queryParams]=
                            "{name:service.nombre,img:pathImg + service.logo }">
                                <div>
                                    <img [src]="pathImg + service.logo" alt="" class="img-fluid">
                                 
                                </div>
                                <div class="testimonial-info">
                                    <h6 class="text-buscado">{{service.nombre}}</h6>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- testimonial section end-->