import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-related-services',
  templateUrl: './related-services.component.html',
  styleUrls: ['./related-services.component.scss']
})
export class RelatedServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
