<!--header start-->
<section class="agency header" id="header">
    <div class="agency bg">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="center-text">
                        <div>
                            <div class="header-text">
                                <h1 class="white-text text-slide">Encuentra el <br>servicio ideal<br> para tu negocio
                                </h1>
                            </div>
                            <div class="link-horizontal">                              
                                  <div class="input-group">
                                    <input type="text" class="form-control buscador" [(ngModel)]="buscando"
                                    (keyup.enter)="searchRedirect()" placeholder="Buscar por servicio o nombre de empresa">
                                    <button class="btn btn-primary" (onclick)="searchRedirect()">Buscar</button>
                                  </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="set-abs abs-horizon-center girl-boy">
        <img src="assets/images/servicios/slide1.png" alt="" class="img-fluid">
    </div>
</section>
<!--header end-->