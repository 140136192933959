import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConnectionService } from './connection.service';

// Menu
export interface Menu {
   path?: string;
   title?: string;
   type?: string;
   icon?: string;
   badgeType?: string;
   badgeValue?: string;
   active?: boolean;
   megaMenu?: boolean;
   megaMenuType?: string; // small, medium, large
   bookmark?: boolean;
   children?: Menu[];
}

@Injectable({
   providedIn: 'root'
})
export class NavService {

   constructor() { }


   MENUITEMS: Menu[] = [
      {
         title: 'Transformacion digital', megaMenu: true, megaMenuType: 'medium', type: 'sub', children: [
            { path: '/UX/UI', title: 'UX/UI', type: 'link' },
            { path: '/enterprice', title: 'Desarrollo Software', type: 'link' },
            { path: '/modern', title: 'Consultoría', type: 'link', },
            { path: '/event', title: 'IA', type: 'link' },
            { path: '/gym', title: 'HARDWARE', type: 'link' },
            { path: '/music', title: 'CLOUD COMPUTING', type: 'link' },
            { path: '/resume', title: 'DESARROLLO WEB', type: 'link' },
            { path: '/enterprice-sass', title: 'IOT', type: 'link' },
         
         ],
      },
      {
         title: 'Infraestructura digital', megaMenu: true, megaMenuType: 'small', type: 'sub', children: [
     
                  { path: '/blog/blog-grid/column-no-sidebar', title: 'No Sidebar', type: 'link' },
                  { path: '/blog/blog-grid/column-left-sidebar', title: 'Left Sidebar', type: 'link' },
                  { path: '/blog/blog-grid/column-right-sidebar', title: 'Right Sidebar', type: 'link' },
                  { path: '/blog/blog-grid/classic-no-sidebar', title: 'Full Width No Sidebar', type: 'link' },
                  { path: '/blog/blog-grid/classic-right-sidebar', title: 'Full Width Right Sidebar', type: 'link' },
                  { path: '/blog/blog-grid/classic-left-sidebar', title: 'Full Width Left Sidebar', type: 'link' }
               
           
     
       

         ]
      },
      {
         title: 'E-Commerce', megaMenu: true, megaMenuType: 'medium', type: 'sub', children: [
            { path: '/404', title: '404', type: 'link' },
            { path: '/page/faq', title: 'FAQ', type: 'link' },
            { path: '/page/typography', title: 'Typography', type: 'link' },
            { path: '/maintenance', title: 'Maintenance', type: 'link' },
            { path: '/page/about-us', title: 'About-us', type: 'link' },

         ]
      },
      {
         title: 'Fintech', megaMenu: true, megaMenuType: 'small', type: 'sub', children: [
            {
               title: 'Elements1', type: 'sub'
            },
            {
               title: 'Elements2', type: 'sub'
            },
            {
               title: 'Elements3', type: 'sub'

            }
         ]
      },
      {
         title: 'Consultoria', megaMenu: true, megaMenuType: 'medium', type: 'sub', children: [
            {
               title: 'Portfolio-Basic', type: 'link'
            },
            {
               title: 'Portfolio-Details', type: 'link'
            },
            {
               title: 'Trending Layout', type: 'link'
            },
            {
               title: 'Portfolio-Masonary', type: 'link'
            },
         ]
      },
      {
         title: 'Plataformas Digitales', megaMenu: true, megaMenuType: 'large', type: 'sub', children: [
            
                  { path: '/shop/collection-left-sidebar/all', title: 'DELIVERY', type: 'link' },
                  { path: '/shop/collection-left-sidebar-two-grid/all', title: 'INSURTECH', type: 'link' },
                  { path: '/shop/collection-left-sidebar-three-grid/all', title: 'AGROTECH', type: 'link' },
                  { path: '/shop/collection-right-sidebar/all', title: 'MOVILIDAD URBANA', type: 'link' },
                  { path: '/shop/collection-right-sidebar-two-grid/all', title: 'EDUTECH', type: 'link' }
              
         ],
      },

   ]

   // Array
   items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
