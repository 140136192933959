import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agency-header',
  templateUrl: './agency-header.component.html',
  styleUrls: ['./agency-header.component.scss']
})
export class AgencyHeaderComponent implements OnInit {
  buscando: any;
  constructor(private router: Router) { }

  ngOnInit() {
  }
  searchRedirect() {
    this.router.navigate(['/search', this.buscando]);
  }
}
