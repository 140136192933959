<app-loader *ngIf="isLoading"></app-loader>
<section class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="top-header">
                <div class="row  ">
                    <div class="col-lg-12  col-md-12">
                        <div class="subscribe">
                            <div class="center-content">
                                <div class="form-group">
                                    <div class="d-flex">
                                        <input type="text" class="form-control"
                                            placeholder="Buscar por servicio o nombre de empresa"
                                            (keyup.enter)="searchRedirect()" [(ngModel)]="buscando">
                                        <div class="button-primary">
                                            <button type="submit" class=" btn btn-default text-white"
                                                (click)="searchRedirect()">
                                                BUSCAR
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


                <div class="row  m-t-50">
                    <div class="col-md-8 m-t-50">
                        <h1 class="title-all-ser">{{name}}</h1>
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-2 m-t-50 pull-right">
                        <img [src]="img">
                    </div>
                </div>
                <div class="row m-t-50">
                    <!-- Collection Sidebar -->
                    <a class="overlay-sidebar"></a>
                    <div class="col-sm-3 collection-filter">
                        <div class="coll_sidebar_sticky">
                            <div class="collection-filter-block">
                                <h3 class="title-sidebar">SERVICIOS POPULARES</h3>
                                <app-agency-sidebar></app-agency-sidebar>
                            </div>

                        </div>
                    </div>
                    <!-- End Collection Sidebar -->
                    <div class="collection-content col">
                        <div class="page-main-content">
                            <div class="agency blog blog-bg" id="blog">
                                <div class="row " *ngIf="!isNull">
                                    <div class="col-md-4 col-sm-12"
                                        style="margin-bottom: 30px; padding-right: 10px; padding-left: 10px;"
                                        *ngFor="let socio of parnert">
                                        <a routerLink="/detail/{{socio.id}}" target="_blank">
                                            <div class="blog-agency h-100">
                                                <div class="blog-contain h-100">
                                                    <!--     <img [src]="pathImg + socio.logo" alt="" class="img-fluid"> -->

                                                    <div class="center-content divLogo">
                                                        <img [src]="socio.logo" alt="Sin logo" class="logo-parnert">
                                                    </div>
                                                    <div class="img-container">
                                                        <div class="">
                                                            <div class="blog-info">
                                                                <!-- <h5 class="blog-head">{{socio.id}}</h5> -->
                                                                <h5 class="blog-head">{{socio.nombre}}</h5>
                                                                <div class="btn-group"
                                                                    *ngFor="let service of socio.servicios ">
                                                                    <span class="tag-service3"><a
                                                                            routerLink="/subcategoria/{{service.id}}"
                                                                            [queryParams]="{name:service.nombre}"
                                                                            class="link-servicio-socio">{{service.nombre}}</a>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-md-12 text-center">
                                        <button class="cargando" (click)="get_partner_category()" *ngIf="showLoadMore">
                                            CARGANDO SOCIOS...
                                        </button>
                                    </div>

                                </div>
                                <div class="alert alert-primary" role="alert" *ngIf="isNull">
                                    <i class="fa fa-info-circle"></i> No existen registros
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>