import { Component, OnInit } from '@angular/core';
import { ConnectionService } from 'src/app/shared/service/connection.service';
import { Menu, NavService } from '../../../service/nav.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public menuItems: Menu[];
  public openSide: boolean = false;
  public activeItem: string = 'home';
  public active: boolean = false;
  public activeChildItem: string = ''
  public overlay: boolean = false;
  menu: any;

  constructor(public navServices: NavService, private apiService: ConnectionService) { }

  ngOnInit() {

    this.get_category()
  }

  get_category() {
    this.apiService.get("api_catalogo/public/api/categorias/", {}).
      subscribe((response: any) => {
        console.log("titulo menu", response)
        this.menuItems = response.data.map(e => {
          return {
            id: e.id,
            path: '/categoria/' + e.id,
            title: e.nombre,
            megaMenu: true,
            megaMenuType: "medium",
            type: "sub",
            children: e.servicios.map(s => {
              return {
                path: '/subcategoria/' + s.id,
                title: s.nombre,
                type: 'link'

              }
            })
          }
        })
        console.log("LOS MENUS", this.menuItems)
      })
  }


  toggleSidebar() {
    this.openSide = !this.openSide
  }

  closeOverlay() {
    this.openSide = false
  }

  //For Active Main menu in Mobile View
  setActive(menuItem) {
    if (this.activeItem === menuItem) {
      this.activeItem = ''
    } else {
      this.activeItem = menuItem
    }
  }

  isActive(item) {
    return this.activeItem === item
  }

  // For Active Child Menu in Mobile View
  setChildActive(subMenu) {
    if (this.activeChildItem === subMenu) {
      this.activeChildItem = ''
    } else {
      this.activeChildItem = subMenu
    }
  }

  ischildActive(subMenu) {
    return this.activeChildItem === subMenu
  }


}
