<section class="agency blog blog-bg" id="blog">
    <div class="container">
        <div class="top-header">
            <div class="row">

                <div class="col-lg-12 col-md-12">
                    <div class="subscribe">
                        <div class="center-content">
                            <div class="form-group">
                                <div class="d-flex">
                                    <input type="text" class="form-control"
                                        placeholder="Buscar por servicio o nombre de empresa"  [(ngModel)]="buscando"
                                        (keyup.enter)="searchRedirect()" >
                                    <div class="button-primary">
                                        <button type="submit" class=" btn btn-default text-white" (click)="searchRedirect()">BUSCAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row p-t-50">

                <div class="col-md-3 col-sm-12 p-t-50">
                    <a>
                        <div class="blog-agency">
                            <div class="blog-contain">
                                <!-- <img [src]="blog.img" alt="" class="img-fluid"> -->
                                <div class="img-container center-content">
                                    <div class="center-content">
                                        <div class="blog-info">
                                            <div class="m-b-10">
                                                <div class="center-content divLogo">
                                                    <img [src]="logo" class="logo-parnert" alt="SIN LOGO">

                                                </div>
                                            </div>

                                            <div class="m-t-20 text-center">
                                                <a href="#formContact"
                                                    class="btn btn-default primary-btn radius-0 btn-contact-info ">
                                                    Contactar
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-9 p-t-50">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12" *ngFor="let socio of detail">

                                <h2 class="title-socio"> {{socio?.serv_nombre}}</h2>
                                <p class="desc-socio">
                                    {{socio?.serv_descripcion}}
                                </p>
                            </div>
                            <div class="col-md-12">
                                <h5 class="atri-socio mt-5 mb-3">SERVICIOS</h5>
                                <div class="btn-group mr-3" *ngFor="let data of services">
                                    <span class="badge badge-secondary mr-2 tag-service"> <a
                                            routerLink="/subcategoria/{{data.id}}" [queryParams]=
                                            "{name:data.nombre}"
                                            class="link-ser">{{data.nombre}}</a></span>


                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="mb-5" *ngFor="let user of contact">
                                    <h4 class="atri-socio mb-3">CONTACTO</h4>
                                    <ul>
                                        <li class="detail-socio">{{user.nombre}}
                                            <span> <a href="{{user.linkedIn}}" target="_blank"><i
                                                        class="fa fa-linkedin ic-link"></i></a> </span>
                                        </li>
                                        <li class="detail-socio">{{user.email}}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="address?.length">
                                <div class="mb-5">
                                    <h4 class="atri-socio mb-3">DIRECCIÓN</h4>
                                    <p class="detail-socio">{{address}}</p>

                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="phone?.length">
                                <div class="mb-5">
                                    <h4 class="atri-socio mb-3">TELÉFONO</h4>
                                    <p class="detail-socio">{{phone}}</p>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="pageWeb?.length">
                                <div class="mb-5">
                                    <h4 class="atri-socio mb-3">PÁGINA WEB</h4>
                                    <a href="{{pageWeb}}" target="_blank" class="detail-socio-enlace">{{pageWeb}}</a>
                                </div>
                            </div>
                        </div>
                        <div class="row" id="formContact">
                            <div class="col-md-12">

                                <div class="theme-card">
                                    <div class="alert alert-success" role="alert" *ngIf="sendContact">
                                        <i class="fa fa-info-circle"></i> {{mesage}}
                                    </div>
                                    <form class="theme-form" [formGroup]="formContactEmpresa" [hidden]="sendContact">
                                        <div class="alert alert-danger" role="alert" *ngIf="inputRequired">
                                            <i class="fa fa-info-circle"></i> {{mesage}}
                                        </div>

                                        <div class="form-row">
                                            <div class="col-md-12">
                                                <label for="name">NOMBRE</label>
                                                <input type="text" class="form-control" id="name" required=""
                                                    formControlName="nombre" maxlength="50">
                                            </div>

                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-6">
                                                <label for="email">CORREO ELECTRÓNICO</label>
                                                <input type="email" class="form-control" id="email" required=""
                                                    formControlName="email">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="telf">TELÉFONO</label>
                                                <input type="text" class="form-control" id="telf" required=""
                                                    formControlName="telefono" maxlength="30">
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-12">
                                                <label for="message">MENSAJE</label>
                                                <textarea class="form-control" rows="5"
                                                    formControlName="mensaje"></textarea>
                                            </div>

                                        </div>
                                        <div class="row text-center">
                                            <div class="col-md-12">
                                                <br><br>
                                                <button class="btn-default primary-btn  btn-contact "
                                                    (click)="onSummit()">CONTACTAR</button>
                                            </div>

                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
            <app-category></app-category>

        </div>
    </div>
</section>