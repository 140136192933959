<!--copyright start-->
<div class="agency copyright">
        <div class="container">
            <div class="row">
                <div class="col-sm-4">
                    <div class="link-horizontal">
                        <ul class="socials-horizontal">
                            <li>
                                <a class="copyright-text" href="mailto:info@citec.com.ec">info@citec.com.ec</a>
                            </li>
                            <li>
                                <a href="https://api.whatsapp.com/send/?phone=593989348227&text=Hola! necesito información.." target="_blank" class="copyright-text spacing" >(593-9) 89 348 227</a>
                                <a href="https://api.whatsapp.com/send/?phone=593987654074&text=Hola! necesito información.." target="_blank" class="copyright-text spacing" > (593-9) 87 654 074</a>

                            </li>
                          
                        </ul>
                     
                    </div>

                </div>
                <div class="col-sm-5">
                    <div class="link-horizontal">
                        <ul class="socials-horizontal">
                            
                            <li class="icon-social">
                                <a href="https://www.instagram.com/citecec/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                            </li>   
                            <li class="icon-social">
                                <a href="https://www.facebook.com/CamaraCITEC/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="icon-social">
                                <a href="https://twitter.com/CITECec" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="icon-social">
                                <a href="https://www.linkedin.com/company/citecec/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                     
                    </div>

                </div>
                <div class="col-sm-3">
                    <div class="link-horizontal">
                       
                         
                        <ul >
                            <li class="footer-social-list">
                                <h6 class="copyright-text text-white pull-right font-12">CATÁLOGO DE SERVICIOS </h6>
                            </li>
                            <li class="footer-social-list">
                               
                                <a href="https://0lmedwdbg3u.typeform.com/to/z3vQLl5t" target="_blank" class="copyright-text text-white text-right font-12">AFÍLIATE</a>
                              
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--copyright start-->
    