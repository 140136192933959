import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgencyCategoryComponent } from './agency/agency-category/agency-category.component';
import { AgencySubcategoryComponent } from './agency/agency-subcategory/agency-subcategory.component';
import { AgencyContentComponent } from './agency/agency-content/agency-content.component';
import { AgencyServicesComponent } from './agency/agency-services/agency-services.component';
import { AgencyComponent } from './agency/agency.component';
import { InputSearchComponent } from './agency/input-search/input-search.component';

const routes: Routes = [
  {
    path: '',
    component: AgencyComponent,
    data: {
      title: "Catálogo de servicios |  Cámara de Innovación y Tecnología Ecuatoriana",
      content: "Catálogo de servicios"
    }
 


  },
  {
    path: 'servicios',
    component: AgencyServicesComponent,
    canActivate: []
  },
  
  {
    path: 'socio',
    component: AgencyContentComponent,
    canActivate: []
  },
  { path: 'detail/:id',
   component: AgencyContentComponent },
   
  {
    path: 'categoria/:id',
    component: AgencyCategoryComponent,
    canActivate: []
  },
  {
    path:'subcategoria/:id',
    component:AgencySubcategoryComponent,
    canActivate: []
  },
  {
    path:'search/:data',
    component:InputSearchComponent,
    canActivate: []
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule { }
