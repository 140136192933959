import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ConnectionService } from '../../../shared/service/connection.service'

@Component({
  selector: 'app-parnert-list',
  templateUrl: './parnert-list.component.html',  
  styleUrls: ['./parnert-list.component.css']
})
export class ParnertListComponent implements OnInit {
  parnert = [];
  category = []
  row: any;
  isNull:any;
  accumulator = 0
  showLoadMore = true
  loading = false;
  isOpened = false;
  isLoading:boolean=true;
  constructor(
    private modalService: NgbModal,
    private apiService: ConnectionService) { }
  ngOnInit(): void {
    this.get_partner()
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

/*   get_partner() {
    this.apiService.get("api_empresas/public/api/empresas/all/", { rows: 10}).
      subscribe((response: any) => {
        this.parnert = response.data
        this.category = response.data.servicios
        
        console.log("lista de socios", this.parnert)


      })
  } */
  get_partner() {
    this.isLoading = true;
    this.accumulator += 18
    console.log("acumula", this.accumulator)
    this.apiService.get("api_empresas/public/api/empresas/all/", { rows: this.accumulator}).
      subscribe((response: any) => {
        if (parseInt(response.records) <= this.accumulator) {
          this.showLoadMore = false
        }
        //console.log("total de registros", response.records)
        this.parnert = response.data
        if (this.parnert.length == 0) {
          this.isNull = true
        }
       // console.log("cargar más...", response)
      })
      this.isLoading = false;
  }





  toggleAccordion() {
    this.isOpened = !this.isOpened;
  }

}
