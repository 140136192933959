<div class="loader-wrapper p-t-50" [class.loderhide]="!show">

  <div class="loader">
    <img src="assets/images/loading.png" alt="">
    <div class="loading-dots mt-4">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</div>