import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from 'src/app/shared/service/connection.service';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit {

  parnert: any | '';
  isNull = false;
  count = 9;
  accumulator = 0;
  showLoadMore = true;
  isOpened = false;
  buscando: any;
  param: any;
  isLoading: boolean = true;
  data: any
  constructor(private route: ActivatedRoute,
    private apiService: ConnectionService, private router: Router) { }

  ngOnInit(): void {
    this.param = this.route.snapshot.paramMap.get('data');
    this.search()
  }
  toggleAccordion() {
    this.isOpened = !this.isOpened;
  }


  search() {
    this.isLoading = true;
    this.showLoadMore = true;
    this.isNull = false;
    this.accumulator += 18;
    if (this.buscando === null || this.buscando == "" || this.buscando === undefined) {
      this.data = this.param
    } else {
      this.data = this.buscando
    }
    
    this.apiService.get("api_empresas/public/api/empresas/search/?nombre=" + this.data, { page: 1, rows: this.accumulator }).
      subscribe((response: any) => {
        this.parnert = response.data
        if (parseInt(response.records) < this.accumulator) {
          this.showLoadMore = false
        }
        if (this.parnert.length == 0) {
          this.isNull = true
        }
        this.isLoading = false;

      })
  }
}
