import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConnectionService } from 'src/app/shared/service/connection.service';

@Component({
  selector: 'app-search-services',
  templateUrl: './search-services.component.html',
  styleUrls: ['./search-services.component.scss']
})
export class SearchServicesComponent implements OnInit {
  category: any;
  pathImg:any;
  mensaje: string;

  @Output()
  propagar = new EventEmitter<string>();

  constructor(private apiService: ConnectionService) { }

  ngOnInit(): void {
    this.get_category()
  }
  get_category() {
    this.apiService.get("api_catalogo/public/api/serviciosMasBuscados/", {}).
      subscribe((response: any) => {
        this.propagar.emit(this.mensaje);
        this.category = response.data
        this.pathImg= response.path
        console.log("lista de categorias", this.category)


      })
  }


  testimonialCarouselOptions = {
    items: 12,
    margin: 30,
    nav: true,
    dots: false,
    navText: ['<img src="./assets/images/agency/testimonial/left.png">', '<img src="./assets/images/agency/testimonial/right.png">'],
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
      0: {
        items: 1.5,
        margin: 10
      },
      575: {
        items: 3,
        margin: 10
      },
      991: {
        items: 5,
        margin: 20
      },
      1199: {
        items: 5,
        margin: 20
      }
    }
  }

}
