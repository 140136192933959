<app-loader *ngIf="isLoading"></app-loader>
<section class="section-b-space" *ngIf="!isLoading">
  <div class="collection-wrapper">
    <div class="container">
      <div class="row">
        <!-- Collection Sidebar -->
        <a class="overlay-sidebar"></a>
        <div class="col-sm-3 collection-filter">
          <div class="coll_sidebar_sticky">
            <div class="collection-filter-block">
              <h3 class="title-sidebar">SERVICIOS POPULARES</h3>
              <app-agency-sidebar></app-agency-sidebar>
            </div>

          </div>
        </div>


        <!-- End Collection Sidebar -->
        <div class="collection-content col">
          <div class="page-main-content">
            <div class="agency blog blog-bg" id="blog">
              <div class="row d-block d-sm-block d-md-none">
                <div class="col-md-12">
                  <div class="accordiones">
                    <div class="accordion-item" [ngClass]="{'opened': isOpened}">
                      <div class="accordion-item-header" (click)="toggleAccordion()">
                        <div class="title-sidebar">SERVICIOS POPULARES</div>
                        <span class="accordion-item-arrow" [ngClass]="{'opened': isOpened}">
                          <i class="fa fa-chevron-down"></i>
                        </span>
                      </div>
                      <div class="accordion-item-body" [ngClass]="{'opened': isOpened}">
                        <app-agency-sidebar></app-agency-sidebar>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="row ">
                <div class="col-md-4 col-sm-12 cardSocio"
                  *ngFor="let socio of parnert | sort:'masBuscado'">
                  <a routerLink="/detail/{{socio.id}}" target="_blank">
                    <div class="blog-agency h-100">
                      <div class="blog-contain h-100">
                        <!--     <img [src]="pathImg + socio.logo" alt="" class="img-fluid"> -->

                        <div class="center-content divLogo">
                          <img [src]="socio.logo" alt="Sin logo" class="logo-parnert">
                        </div>
                        <div class="img-container">
                          <div class="">
                            <div class="blog-info">

                              <h5 class="blog-head">{{socio.nombre}}</h5>
                              <div class="btn-group" *ngFor="let service of socio.servicios ">
                                <span class="tag-service3"> <a routerLink="/subcategoria/{{service.id}}"
                                    [queryParams]="{name:service.nombre}"
                                    class="link-servicio-socio">{{service.nombre}}</a></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-md-12 text-center">
                  <button class="cargando" [disabled]="cargando" (click)="get_partner()">
                    CARGANDO SOCIOS...
                  </button>
              
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>