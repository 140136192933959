import { Component, OnInit } from '@angular/core';
import { ConnectionService } from 'src/app/shared/service/connection.service';

@Component({
  selector: 'app-agency-sidebar',
  templateUrl: './agency-sidebar.component.html',
  styleUrls: ['./agency-sidebar.component.scss']
})
export class AgencySidebarComponent implements OnInit {
serviceP:any;

  constructor(private apiService: ConnectionService) { }

  ngOnInit(): void {
    this.get_service_populares()
  }
  get_service_populares() {
    this.apiService.get("api_catalogo/public/api/serviciosMasPopulares/", {}).
      subscribe((response: any) => {
       this.serviceP=response.data
        //console.log("SUBCATEGORIAS ", this.serviceP)


      })
  }



}
