import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from 'src/app/shared/service/connection.service';

@Component({
  selector: 'app-agency-list-category',
  templateUrl: './agency-list-category.component.html',
  styleUrls: ['./agency-list-category.component.scss']
})
export class AgencyListCategoryComponent implements OnInit {
  id: any;
  parnert: any;
  isNull = false;
  accumulator = 0;
  showLoadMore = true;
  buscando: any;
  isLoading:boolean=true;
  constructor(
    private route: ActivatedRoute,
    private apiService: ConnectionService,
    private router: Router) { }
  name: any
  img: any;

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.get_partner_category()

    this.route.queryParams.subscribe(params => {
      this.name = params['name'];
      this.img = params['img']
    });

  }


  get_partner_category() {
    this.accumulator += 18
    this.isLoading=true
    this.apiService.get("api_empresas/public/api/empresas/search/categoria/" + this.id, { page: 1, rows: this.accumulator }).
      subscribe((response: any) => {
        if (parseInt(response.records) <= this.accumulator) {
          this.showLoadMore = false
        }
        this.parnert = response.data
        if (this.parnert.length == 0) {
          this.isNull = true
        }
        this.isLoading=false
      })
  }




  searchRedirect() {
    this.router.navigate(['/search', this.buscando]);
  }






}
